<template>
  <v-overlay :value="show">
    <v-card
      v-click-outside="close"
      light
      class="pb-5"
      color="#333333"
      width="1300"
    >
      <v-card-title class="white--text d-flex justify-space-between">
        <span>Создать УВР из ЭПИ</span>
        <v-btn
          color="error"
          small
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-text-field
        v-model="search"
        placeholder="Поиск"
        hide-details="auto"
        background-color="#EDEDED"
        class="ba-0 rounded-0"
        filled
      />
      <v-data-table
        v-model="selected"
        checkbox-color="teal lighten-2"
        disable-sort
        hide-default-footer
        :single-select="false"
        item-key="rqId"
        show-select
        :items-per-page="-1"
        height="500"
        fixed-header
        :items="documents"
        :headers="headers"
        class="rounded-0"
        :search="search"
      />
      <div class="d-flex justify-end mt-4">
        <v-col class="py-0 d-flex justify-end">
          <v-btn
            color="#5CB7B1"
            elevation="0"
            dark
            @click="submit"
          >
            Создать УВР и Пропуск
          </v-btn>
        </v-col>
      </div>
    </v-card>
  </v-overlay>
</template>
<script>
import closeOnEscape from '@/mixins/close-on-escape.mixin.js'
import {mapGetters} from "vuex";
import axios from "axios";
import {UVR} from "@/http-routes";

export default {
  mixins:[closeOnEscape],
  props:{
    show:{
      required:true,
      type:Boolean
    },
  },
  data:() => ({
    search:"",
    selected:[],
    headers:[
      {
        text:"Номер ТС",
        value:"transportNumber"
      },
      {
        text:"CMR",
        value:"cmr"
      },
      {
        text:"Рег.номер",
        value:"regNumA"
      },
      {
        text:"Перевозчик",
        value:"carrierName"
      },
      {
        text:"Получатель",
        value:"recipientName"
      }
    ],
    documents:[],
  }),
  computed:{
    ...mapGetters({
      user:"auth/getUser"
    }),
    height(){
      return window.innerHeight - 500
    }
  },
  watch:{
    show:{
      handler(nv){
        if(nv === true){
          this.fetchDocuments()
        }
      },
      immediate:true
    },
  },
  methods:{
    containsDistinct(){
      const documents = this.selected.map((document) => document.transportNumber)
      return [...new Set(documents)].length > 1
    },
    submit(){
      if(this.containsDistinct()){
        return this.$warning('Выбраны документы для разных ТС')
      }
      this.$store.dispatch('uvr/createPassFromEpi', this.selected).then((res) => {
        this.$success(`Создан пропуск: ${res.data.id}`)
      }).catch(() => this.$error())
    },
    fetchDocuments(){
      axios.get(`${UVR}declaration/getEpiFromSvh?userId=${this.user.user.id}`).then((res) => {
        this.documents = res.data
      }).catch(() => this.$error())
    },
    unset(){
      this.selected = []
      this.documents = []
    },
    close(){
      this.unset()
      this.$emit('update:show', false)
    }
  }
}
</script>
